


















/**
 * ToDo
 * 1. 새로고침 후 swipe 상태 수정
 * 2. 이미지 없을 경우
 */
import { Vue, Component, Prop } from 'vue-property-decorator';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
import { EventBus } from '@/config/config';

@Component
export default class ThumbnailList extends Vue {
  @Prop({
    required: true
  })
  public thumbnail!: any[];
  @Prop()
  public defaultImg!: any;

  public swiper: any = null;

  public mounted() {
    this.activeSwiper();
  }
  public updated() {
    this.activeSwiper();
  }

  public thumbnailDetail(index) {
    EventBus.$emit('thumbnailIndex', index);
    this.$modal.show('thumbnailDetail');
  }
  public activeSwiper() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
    const swiper = new Swiper('.detail-thumbnail-container .swiper-container', {
      slidesPerView: 'auto',
      loop: true,
      observer: true,
      observeParents: true,
      pagination: {
        el: '.detail-thumbnail-container .swiper-pagination',
        type: 'fraction',
      },
    });
  }
}
